import React from 'react'
import { graphql } from 'gatsby'

import Carousel from '../../../components/carousel'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'MSR UL Ratings' },
    { img: data.i2, caption: 'UL head of Wall' },
  ];

  return (
    <Layout>
      <SEO title="System Components: EPICORE MSR Floor System: U.L. Rating" keywords={[]}/>
      <div className="container gallery-container">
        <h2 className='subtitle subtitle--xl color-primary'>System Components: EPICORE MSR Floor System</h2>
        <h1 className='title title--xxxl color-primary'>U.L. Rating</h1>
        <div className='carousel-container carousel-container--lg'>
          <Carousel
            content={images}
            carWidth={846}
            carHeight={476}
          />
        </div>
        <div>
          <p>The Epicore MSR Composite Floor System has it's own exclusive Underwriters
          Laboratories Rating U.L. D-938. An abbreviated D-938 assembly is shown here, however
          one should refer to your U.L. Fire Resistance Directory for more specific details. A
          4" regular weight Epicore MSR slab thickness achieves a one-hour rating. A two-hour
          U.L. rating is achieved with a 5" regular weight Epicore MSR slab thickness.</p>

          <p>It is important to realize that Epicore MSR U.L. ratings are based on bare
          Epicore MSR deck with concrete and wire mesh. The Epicore MSR deck does not require
          spray on fire protection, nor does it require a fire-rated drywall ceiling to
          achieve its rating. This not only saves money and reduces costs, it also gives
          you unrestricted flexibility in what you may want to put up into the ceiling
          (unlike joist-type floor/ceiling assembles which are very restrictive).</p>

          <p>In addition to our basic U.L. fire ratings for Epicore MSR, we also have
          two exclusive Head-of-Wall U.L. fire ratings for the Epicore MSR Floor System
          supported by load-bearing metal stud walls. An abbreviated U.L. # HW-S-0115
          is shown here (HW-S-0119 not shown). These important tests and approvals are
          required by most City Building Officials in the USA. Note that our Head-of-Wall
          does not require any fire caulking which reduces both cost and time.</p>

          <p>Please consult Infinity Structures for more
          information regarding our superior U.L. fire ratings.</p>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query {
        i1: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ul-rating/gallery/01-41614.png" }) {
            ...GalleryCarouselTechnical1_77ImageFragment },
        i2: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ul-rating/gallery/02-43046.png" }) {
            ...GalleryCarouselTechnical1_77ImageFragment },
    }
`
